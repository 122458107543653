export const membersAppDefId = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const ecomAppDefID = '1380b703-ce81-ff05-f115-39571d94dfcd';
export const addressesAppDefId = '1505b775-e885-eb1b-b665-1e485d9bf90e';
export const myAddressesPageId = 'my_addresses';
export const orderHistoryPageId = 'order_history';
export const myWalletAppDefId = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';
export const myWalletPageId = 'my_wallet';

export const myWalletInstallDefinition = {
  appDefinitionId: myWalletAppDefId,
  pageId: myWalletPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addApplication',
};

export const myAdressesInstallDefinition = {
  appDefinitionId: addressesAppDefId,
  pageId: myAddressesPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addApplication',
};

export const myOrdersInstallDefinition = {
  appDefinitionId: ecomAppDefID,
  pageId: orderHistoryPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addSection',
};

export const enum Events {
  manageStoresEvent = 'manageStoresEvent',
  deletePage = 'deletePage',
  hidePage = 'hidePage',
  subPage = 'subPage',
}

export const applicationId = 15;
