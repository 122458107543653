import {Events} from '../constants';
import {Experiments} from '../../common/experiments/Experiments';
import {translate as t} from './translation';

export function getAppManifest(experiments: Experiments) {
  if (!experiments.enabled('specs.stores.NewPagesPanel')) {
    return {};
  }

  const pageActions = {
    default: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Delete'),
        event: Events.deletePage,
        icon: 'deleteAction',
        type: 'page_remove',
      },
      {
        title: t('Hide'),
        event: Events.hidePage,
        icon: 'hideAction',
        type: 'page_hide',
      },
      {
        title: t('Subpage'),
        event: Events.subPage,
        icon: 'Subpage',
        type: 'page_Subpage',
      },
    ],
  };

  const pageSettings = {
    default: [
      {
        title: t('Info'),
        helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
        type: 'page_info',
      },
      {
        title: t('Layout'),
        type: 'layout',
      },
      {
        title: t('Permissions'),
        type: 'permissions',
      },
      {
        title: t('Seo'),
        type: 'seo',
      },
    ],
  };

  const applicationSettings = {
    default: {
      displayName: t('Stores Pages'),
      helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
    },
  };

  const applicationActions = {
    default: {
      defaultValues: [
        {
          title: t('Manage'),
          event: Events.manageStoresEvent,
          icon: 'settingsAction',
        },
      ],
    },
  };

  const pageDescriptors = {
    default: {
      icon: 'storesPageType',
    },
  };

  return {
    pages: {
      pageActions,
      pageSettings,
      applicationSettings,
      applicationActions,
      pageDescriptors,
    },
  };
}
